<template>
  <div id="Enclosure">
    <CSBreadcrumb />
    <div class="result-panel">
        <CSTable>
            <template v-slot:thead>
                <tr>
                    <th>名称</th>
                    <th>操作</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="(attachment, index) in attachmentList"
                    :key="attachment.id"
                >
                    <td>{{attachment.content.split("/")[attachment.content.split("/").length - 1]}}</td>
                    <td>
                        <div class="btn-group">
                            <button
                                type="button"
                                class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                操作
                            </button>
                            <ul class="dropdown-menu" style="min-width: 76px;width: 100px;">
                                <li @click="deleteAttachment(attachment.id, index)"><a>删除</a></li>
                                <li v-if="judge(attachment.content)" @click="lookImage(attachment.content)"><a style="width: 100%;">查看</a></li>
                                <li><a style="width: 100%;" target="_blank" :href="attachment.content.replace('https://alioss.techops.cn', downloadFileOssDomain)">下载</a></li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </template>
        </CSTable>
    </div>
    <ViewImageModal />
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import {STORAGE_KEY} from "@/constant";
import {downloadFileOssDomain, commonImgOssDomain, deleteAttachmentUrl} from "@/requestUrl";
import ViewImageModal from "@/components/ViewImageModal";
import CSTable from "@/components/common/CSTable";


export default {
  name: "Enclosure",
  components: {
      CSTable,
      ViewImageModal,
    CSBreadcrumb,
  },
  data() {
    return {
        downloadFileOssDomain,
        commonImgOssDomain,
      attachmentList: JSON.parse(sessionStorage.getItem(STORAGE_KEY.CONTRACT_ATTACHMENTS)),
    }
  },
  created() {

  },
  methods: {
    /**
     * 删除合同附件
     * @param {Number} id 附件id
     * @param {Number} index 删除附件的下标
     * */
    deleteAttachment(id, index) {
        this.$CSDialog.confirm({
            title: '提示',
            message: '确定删除吗？',
            onConfirm: () => {
                this.$fly.get(deleteAttachmentUrl, {
                    id
                })
                .then(res => {
                    if (res.code !== 0) {
                        return ;
                    }
                    this.$CSDialog.instance.closeDialog();
                    this.attachmentList.splice(index, 1);
                })
            }
        })

    },
    judge(attachment) {
      let look = ["png", "jpg", "jpeg", "gif", "webp"].includes(attachment.split(".")[attachment.split(".").length - 1]);
      return look;
    },
    lookImage(photoSrc) {
        this.$vc.emit(this.$route.path, "viewImageModal", "view", {
            photos: photoSrc,
            title: '查看'
        });
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
